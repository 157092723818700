import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'
import { SelectableLocationType } from '@/calendesk/models/SelectableLocationType'
import { LocationType } from '@/calendesk/models/LocationType'
import { trans } from '@/calendesk/prototypes/trans'
import SelectableService from '@/calendesk/models/SelectableService'

export default class SelectableLocation {
  public type!: SelectableLocationType
  public serviceLocations!: Array<ServiceLocation> | null

  /**
   * Gets the first location in the serviceLocations array, if any.
   * @returns {ServiceLocation | null} The first location or null if there are no serviceLocations.
   */
  public getFirstServiceLocation (): ServiceLocation | null {
    if (this.serviceLocations && this.serviceLocations.length > 0) {
      return this.serviceLocations[0]
    } else {
      return null
    }
  }

  /**
   * Gets the first location assigned to the provided SelectableService.
   * @param {SelectableService} selectableService The service to check for assigned locations.
   * @returns {ServiceLocation | null} The first assigned location or null if none are found.
   */
  public getFirstServiceAssignedLocation (selectableService: SelectableService): ServiceLocation | null {
    if (this.serviceLocations && this.serviceLocations.length > 0) {
      for (const location of selectableService.service.locations) {
        if (this.serviceLocations.some(serviceLocation => serviceLocation.locationId === location.locationId)) {
          return location
        }
      }
    }
    return null
  }

  /**
   * Get all the serviceLocations.
   * @returns {Array<ServiceLocation> | null} The serviceLocations or null if there are none.
   */
  public getServiceLocations (): Array<ServiceLocation> {
    return this.serviceLocations || []
  }

  public getTitle (): string|null {
    switch (this.type) {
      case SelectableLocationType.ADDRESS:
      case SelectableLocationType.OTHER: {
        const first = this.getFirstServiceLocation()

        if (first && first.name) {
          return first.name
        }

        return trans('service_location_' + this.type)
      }
      case SelectableLocationType.PHONE:
        return trans('service_location_2')
      case SelectableLocationType.ONLINE:
        return trans('selectable_location_online_title')
      case SelectableLocationType.NO_LOCATION:
        return trans('service_location_4')
      default:
        return null
    }
  }

  public getSubtitle (): string | null {
    switch (this.type) {
      case SelectableLocationType.ADDRESS:
      case SelectableLocationType.OTHER: {
        const first = this.getFirstServiceLocation()
        return first && first.data ? first.data : null
      }

      case SelectableLocationType.PHONE: {
        const phoneLocationNames = [...new Set(this.getServiceLocations()
          .filter(location => location.type === LocationType.PHONE && location.name)
          .map(location => location.name))]

        return phoneLocationNames.join(', ') || null
      }

      case SelectableLocationType.ONLINE: {
        const onlineTypes = [
          LocationType.GOOGLE_MEET,
          LocationType.ZOOM,
          LocationType.TEAMS,
          LocationType.SKYPE,
          LocationType.WHATSAPP]

        const onlineLocationNames = [...new Set(this.getServiceLocations()
          .filter(location => onlineTypes.includes(location.type))
          .map(location => trans('service_location_' + location.type)))]

        return onlineLocationNames.join(', ') || null
      }
      default:
        return null
    }
  }

  getIconSize (): string|null {
    switch (this.type) {
      case SelectableLocationType.OTHER:
      case SelectableLocationType.ADDRESS:
      case SelectableLocationType.NO_LOCATION:
        return '35'
      case SelectableLocationType.PHONE:
        return '35'
      case SelectableLocationType.ONLINE:
        return '45'
      default:
        return null
    }
  }

  getIcon (): string|null {
    switch (this.type) {
      case SelectableLocationType.OTHER:
      case SelectableLocationType.ADDRESS:
      case SelectableLocationType.NO_LOCATION:
        return '$marker'
      case SelectableLocationType.PHONE:
        return '$phone'
      case SelectableLocationType.ONLINE:
        return '$video'
      default:
        return null
    }
  }
}
