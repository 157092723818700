import Employee from '@/calendesk/models/DTO/Response/Employee'
import { trans } from '@/calendesk/prototypes/trans'

export default class SelectableEmployee {
  public employee: Employee | null = null
  public isAny = false
  public manuallySelected = false
  public employees: Array<Employee> = []

  public constructor (employee: Employee | null, isAny = false, allEmployees: Array<Employee> = []) {
    this.employee = employee
    this.isAny = isAny

    if (allEmployees.length > 0) {
      this.employees = allEmployees
    } else if (this.employee) {
      this.employees.push(this.employee)
    }
  }

  public getEmployee () {
    if (this.employee) {
      return this.employee
    }

    return null
  }

  public getDefaultImageUrl (): string | null {
    if (this.employee) {
      return this.employee.getDefaultImageUrl()
    }

    return require('@/assets/placeholder.jpg')
  }

  public getId (): number {
    if (this.employee) {
      return this.employee.id
    }

    return 0
  }

  public getFullName (): string {
    if (this.employee) {
      return this.employee.getFullName()
    }

    return trans('any_person')
  }

  public getTitle (): string | null {
    if (this.employee) {
      return this.employee.getTitle()
    }

    return null
  }

  public getDescription (): string | null {
    if (this.employee) {
      return this.employee.getDescription()
    }

    return null
  }

  public getFacebookUrl (): string | null {
    if (this.employee) {
      return this.employee.getFacebookUrl()
    }

    return null
  }

  public getInstagramUrl (): string | null {
    if (this.employee) {
      return this.employee.getInstagramUrl()
    }

    return null
  }

  public getYoutubeUrl (): string | null {
    if (this.employee) {
      return this.employee.getYoutubeUrl()
    }

    return null
  }

  public getTwitterUrl (): string | null {
    if (this.employee) {
      return this.employee.getTwitterUrl()
    }

    return null
  }

  public getLinkedInUrl (): string | null {
    if (this.employee) {
      return this.employee.getLinkedInUrl()
    }

    return null
  }
}
