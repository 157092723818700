import SelectableLocation from '@/calendesk/models/SelectableLocation'
import Service from '@/calendesk/models/DTO/Response/Service'
import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'
import { SelectableLocationType } from '@/calendesk/models/SelectableLocationType'
import { LocationType } from '@/calendesk/models/LocationType'

export default class LocationController {
  public static serviceLocationBelongsToSelectableLocationGroup (location: ServiceLocation, selectableLocation: SelectableLocation): boolean {
    const serviceToSelectableTypeMapping: { [key in number]: SelectableLocationType } = {
      [LocationType.IN_PERSON]: SelectableLocationType.ADDRESS,
      [LocationType.OTHER]: SelectableLocationType.OTHER,
      [LocationType.PHONE]: SelectableLocationType.PHONE,
      [LocationType.GOOGLE_MEET]: SelectableLocationType.ONLINE,
      [LocationType.ZOOM]: SelectableLocationType.ONLINE,
      [LocationType.TEAMS]: SelectableLocationType.ONLINE,
      [LocationType.SKYPE]: SelectableLocationType.ONLINE,
      [LocationType.WHATSAPP]: SelectableLocationType.ONLINE
    }

    if (location.type === LocationType.IN_PERSON || location.type === LocationType.OTHER) {
      const first = selectableLocation.getFirstServiceLocation()

      if (first) {
        return first.locationId === location.locationId
      }

      return false
    }

    const expectedSelectableType = serviceToSelectableTypeMapping[location.type]
    return selectableLocation.type === expectedSelectableType
  }

  public static createSelectableLocationsFromServices (services: Array<Service>): Array<SelectableLocation> {
    // Make sure we use only once the location, each location can be assigned to multiple services
    const locationMap = services.reduce((accumulator, service) => {
      if (service.locations.length === 0) {
        // Handle services without locations
        accumulator.set('no-location', null)
      } else {
        service.locations.forEach(location => {
          accumulator.set(location.locationId, location)
        })
      }
      return accumulator
    }, new Map())

    const selectableLocations: Array<SelectableLocation> = []
    const phoneLocations: Array<ServiceLocation> = []
    const onlineLocations: Array<ServiceLocation> = []

    // Process each location exactly once
    locationMap.forEach((location, key) => {
      if (key === 'no-location') {
        if (locationMap.size > 1) { // Add "no-location" only if there are other locations
          const noLocationSelectable = new SelectableLocation()
          noLocationSelectable.type = SelectableLocationType.NO_LOCATION
          noLocationSelectable.serviceLocations = []
          selectableLocations.push(noLocationSelectable)
        }
      } else {
        switch (location.type) {
          case LocationType.IN_PERSON:
          case LocationType.OTHER: {
            const singleLocationSelectable = new SelectableLocation()
            singleLocationSelectable.type = location.type === LocationType.IN_PERSON
              ? SelectableLocationType.ADDRESS
              : SelectableLocationType.OTHER
            singleLocationSelectable.serviceLocations = [location]
            selectableLocations.push(singleLocationSelectable)
          }
            break
          case LocationType.PHONE:
            phoneLocations.push(location)
            break
          case LocationType.GOOGLE_MEET:
          case LocationType.ZOOM:
          case LocationType.TEAMS:
          case LocationType.SKYPE:
          case LocationType.WHATSAPP:
            onlineLocations.push(location)
            break
        }
      }
    })

    // Helper function to add group serviceLocations to selectableLocations if they exist
    const addGroupLocationsToSelectable = (type: SelectableLocationType, locations: Array<ServiceLocation>) => {
      if (locations.length > 0) {
        const selectableLocation = new SelectableLocation()
        selectableLocation.type = type
        selectableLocation.serviceLocations = locations
        selectableLocations.push(selectableLocation)
      }
    }

    // Add group serviceLocations for PHONE and ONLINE
    addGroupLocationsToSelectable(SelectableLocationType.PHONE, phoneLocations)
    addGroupLocationsToSelectable(SelectableLocationType.ONLINE, onlineLocations)

    // Sort selectableLocations based on the defined order
    selectableLocations.sort((a, b) => {
      const sortOrder = {
        [SelectableLocationType.ONLINE]: 1,
        [SelectableLocationType.PHONE]: 2,
        [SelectableLocationType.ADDRESS]: 3,
        [SelectableLocationType.OTHER]: 4,
        [SelectableLocationType.NO_LOCATION]: 5
      }

      return sortOrder[a.type] - sortOrder[b.type]
    })

    return selectableLocations
  }
}
