import SelectableService from '@/calendesk/models/SelectableService'
import Category from '@/calendesk/models/DTO/Response/Category'

export default class SelectableCategory {
  public selectableServices: SelectableService[]
  public category: Category

  public constructor (selectableServices: SelectableService[], category: Category) {
    this.selectableServices = selectableServices
    this.category = category
  }
}
