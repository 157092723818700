export class LocationType {
  static IN_PERSON = 1;
  static PHONE = 2;
  static GOOGLE_MEET = 3;
  static OTHER = 4;
  static ZOOM = 5;
  static TEAMS = 6;
  static SKYPE = 7;
  static WHATSAPP = 8;
}
